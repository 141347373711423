<template>
    <div class="div-companies bg-white content-wrapper">
        <div v-show="media_key" class="modal fade text-center" id="exampleModalImage" tabindex="-1"
            aria-labelledby="exampleModalImageLabel" aria-hidden="true">
            <div class="modal-dialog modalImage">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalImageLabel">Sugestão Selecionada</h5>
                    </div>
                    <div class="modal-body d-flex justify-content-center">
                        <img v-if="media_key" :src="path + media_key" id="preview-banner" alt="Banner"
                            class="img-fluid custom-image">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><b>Fechar</b></button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="sugestionDescription" class="modal fade" id="exampleModal" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Sugestão Selecionada</h5>
                    </div>
                    <div class="modal-body" style="line-break: anywhere;">
                        <p class="p-0">
                            {{ sugestionDescription }}
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><b>Fechar</b></button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="deleteSugestion" class="modal fade" id="modalDelete" ref="modalDelete" tabindex="-1"
            aria-labelledby="exampleModalImageLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalImageLabel">Deseja realmente excluir a sugestão?</h5>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm ml-2"
                            data-bs-dismiss="modal">Cancelar</button>
                        <button @click="remove(deleteSugestion)" class="btn btn-danger btn-sm ml-2" title="Excluir">
                            Confirmar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <section class="content-header p-1 px-2">
            <div class="div-header container-fluid">
                <div style="border-bottom: 1px solid #0001" class="row mb-2">
                    <div class="col-sm-12 d-flex justify-content-between align-items-center">
                        <h4 style="color: #0007" class="mt-3">
                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                            Sugestões
                        </h4>
                    </div>
                </div>
            </div>
        </section>

        <div class="card">
            <div class="d-flex justify-content-end align-items-center m-2 p-1">
                <div class="w-50">
                    <button @click="getDataToExportXlsx" class="btn btn-outline-primary">Exportar</button>
                </div>
                <span>Início:</span>
                <b-date class="mr-3 ml-1" :language="pt" v-model="startDate" :format="'dd/MM/yyyy'"
                    placeholder="Selecione a data..." />
                <span>Fim:</span>
                <b-date class="mr-5 ml-1" :language="pt" v-model="finishDate" :format="'dd/MM/yyyy'"
                    placeholder="Selecione a data..." />
                <div style="width: 400px;" class="search input-group input-group-sm">
                    <input @keyup.enter="getSugestions" type="text" class="form-control"
                        :placeholder="`Pesquisar por sugestões`" v-model="searchTerm" />
                    <i v-if="!processing" class="fas fa-search lupa-style" title="Perquisar empresa"></i>
                    <div v-show="processing" class="spinner-border search-admin" role="status"
                        style="height: 1rem; width: 1rem; z-index:10" />
                </div>
            </div>
        </div>

        <div class="card-body table-responsive p-0">
            <table class="table table-hover table-stripped table-head-fixed">
                <thead>
                    <tr>
                        <th>Criação</th>
                        <th>Empresa</th>
                        <th>Descrição</th>
                        <th>Anexo</th>
                        <th>Curtidas</th>
                        <th>Publicada</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="index" v-for="(sugestion, index) in sugestions">
                        <td class="col-1">{{ moment(sugestion.created_at).format('DD/MM/YYYY') }}</td>
                        <td class="col-2">{{ sugestion.company.name }}</td>
                        <td>
                            <p style="cursor: pointer;" @click="sugestionInfoToShow(sugestion)" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">{{ truncate(sugestion.description, 70) }}</p>
                        </td>
                        <td class="text-center">
                            <button v-if="sugestion.media" @click="sugestionMediaToShow(sugestion)"
                                class="btn btn-primary btn-sm ml-2" data-bs-toggle="modal"
                                data-bs-target="#exampleModalImage" title="Anexo">
                                <i class="fa fa-paperclip" aria-hidden="true"></i>
                            </button>
                            <button v-else class="btn btn-secondary btn-sm ml-2" title="Sem anexo" disabled>
                                <i class="fa fa-paperclip" aria-hidden="true"></i>
                            </button>
                        </td>
                        <td class='text-center'>{{ sugestion.likes.length }}</td>
                        <td>
                            <label class="switch" :for="`activeInput-${index}`">
                                <input @change="authorizeSugestion(sugestion, index)" type="checkbox"
                                    :id="`activeInput-${index}`" :checked="sugestion.authorized">
                                <span class="slider round"></span>
                            </label>
                        </td>
                        <td>
                            <button @click="prepareToRemove(sugestion._id)" data-bs-toggle="modal"
                                data-bs-target="#modalDelete" class="btn btn-danger btn-sm ml-2" title="Excluir">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <nav class="mt-3">
            <ul class="pagination justify-content-center">
                <li class="page-item">
                    <button :class="pagination.page === 1 ? 'page-link disabled' : 'page-link'"
                        :disabled="pagination.page === 1 ? true : false"
                        :style="pagination.page === 1 ? { cursor: 'not-allowed' } : {}" @click="firstPage()">Primeira
                    </button>
                </li>
                <li class="page-item">
                    <button :class="pagination.page === 1 ? 'page-link disabled' : 'page-link'"
                        :disabled="pagination.page === 1 ? true : false"
                        :style="pagination.page === 1 ? { cursor: 'not-allowed' } : {}" @click="previousPage()">Anterior
                    </button>
                </li>
                <li class="page-item">
                    <button :class="calcTotalPages() ? 'page-link disabled' : 'page-link'" :disabled="calcTotalPages()"
                        :style="calcTotalPages() ? { cursor: 'not-allowed' } : {}" @click="nextPage()">Próxima
                    </button>
                </li>
                <li class="page-item">
                    <button :class="calcTotalPages() ? 'page-link disabled' : 'page-link'" :disabled="calcTotalPages()"
                        :style="calcTotalPages() ? { cursor: 'not-allowed' } : {}" @click="lastPage()">Última
                    </button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>

import moment from 'moment-timezone'
import * as lodash from 'lodash'
import "moment/locale/pt-br.js"
import {
    truncate,
    urlS3
} from "./../../../config/global"
import Datepicker from 'vuejs-datepicker';
import xlsx from 'json-as-xlsx';


export default {
    data() {
        return {
            moment,
            truncate,
            urlS3,
            processing: false,

            searchTerm: '',
            sugestions: [],
            sugestionDescription: null,

            deleteSugestion: null,

            media_key: null,

            pagination: {
                page: 1,
                totalPages: 1
            },

            path: process.env.VUE_APP_URL_IMAGE,
            startDate: new Date(new Date() - (86400000 * 10)),
            finishDate: new Date(),
            pt: {
                language: 'Brazilian',
                months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                monthsAbbr: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                yearSuffix: ''
            }
        }
    },

    components: {
        'b-date': Datepicker
    },

    computed: {
        generateCols() {
            return [
                {
                    label: 'Empresa',
                    key: (row) => row.company?.name || row.company?._id
                },
                {
                    label: 'Funcionário',
                    key: (row) => row.employee?.name || row.employee?._id
                },
                {
                    label: 'Descrição',
                    key: 'description'
                },
                {
                    label: 'Curtidas',
                    key: (row) => row.likes.length
                },
                {
                    label: 'Mídia',
                    key: (row) => row.media || ''
                },
                {
                    label: 'Data postagem',
                    key: (row) => new Date(row.created_at).toLocaleDateString('pt-BR', {
                        year: 'numeric',
                        day: 'numeric',
                        month: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    })
                }
            ]
        }
    },

    watch: {
        'pagination.page'() {
            this.getSugestions()
        },

        media_key(value) {
            if (!value) this.path = null
        },

        searchTerm: function () {
            this.processing = true
            this.debouncedGetAnswer()
        },

        startDate(newValue, oldValue) {
            if(newValue.getTime() > this.finishDate.getTime()) {
                this.startDate = new Date(oldValue.getTime())
                this.$toasted.global.defaultError({ msg: 'a data de início não pode ser menor que a data final' })
            } else {
                this.getSugestions()
            }
        },

        finishDate(newValue, oldValue) {
            if(newValue.getTime() < this.startDate.getTime()) {
                this.finishDate = new Date(oldValue.getTime())
                this.$toasted.global.defaultError({ msg: 'a data final não pode ser menor que a data inicial' })
            } else {
                this.getSugestions()
            }
        }

    },

    methods: {
        /* MÉTODOS DE PAGINAÇÃO */
        calcTotalPages() {
            return !(this.pagination.page < this.pagination.totalPages)
        },

        firstPage() {
            this.pagination.page = 1
        },

        lastPage() {
            this.pagination.page = this.pagination.totalPages
        },

        nextPage() {
            this.pagination.page++
        },

        previousPage() {
            this.pagination.page--
        },
        /* FIM MÉTODOS DE PAGINAÇÃO */

        sugestionMediaToShow({ media }) {
            this.media_key = media
        },

        sugestionInfoToShow({ description }) {
            this.sugestionDescription = description
        },

        hideModal() {
            document.querySelector('#modalDelete').classList.remove('show')
            document.querySelector('.modal-backdrop').classList.remove('show')
        },

        authorizeSugestion({ company, _id, authorized }, index) {
            this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`
            this.$tallos_admin_api.put(`sugestions/authorize/${this.$store.state.admin.perfil}`, {
                company_id: company._id,
                sugestion_id: _id,
                employee_role: this.$store.state.admin.perfil,
                authorize: !authorized
            })
                .then(() => {
                    this.sugestions[index].authorized = !authorized
                })
                .catch(() => this.$toasted.global.defaultError({ msg: 'Erro na autorização de Sugestão.' }))
        },

        getSugestions() {
            this.processing = true
            let url = `sugestions/index/${this.$store.state.admin.perfil}?page=${this.pagination.page}`
            if (this.searchTerm) {
                url += `&searchTerm=${encodeURI(this.searchTerm)}`
            }

            if (this.startDate && this.finishDate) {
                url += `&startAt=${this.startDate.getTime()}&finishAt=${this.finishDate.getTime()}`
            }

            this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`

            this.$tallos_admin_api.get(url)
                .then(response => {
                    this.sugestions = response.data.docs.map(doc => {
                        doc.description = doc.description.replace(/(&#34;|&#39;)/g, `"`)
                        return doc
                    })
                    this.pagination.totalPages = response.data.pages
                    this.processing = false
                })
                .catch(() => {
                    this.processing = false
                    this.$toasted.global.defaultError({ msg: 'Erro na busca de Sugestões.' })
                    this.$router.go(-1)
                })
        },

        getDataToExportXlsx: async function () {
            let url = `sugestions/export/${this.$store.state.admin.perfil}?`
            if (this.startDate && this.finishDate) {
                url += `startAt=${this.startDate.getTime()}&finishAt=${this.finishDate.getTime()}`
            }
            this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`
            let response = await this.$tallos_admin_api.get(url)
            if (!response.data.length) {
                return this.$toasted.global.defaultError({ msg: 'Não há dados a ser gerados' })
            }
            let cols = this.generateCols
            /**
             * @type {import('json-as-xlsx').IJsonSheet[]}
             */
            const data = [
                {
                    sheet: "Sugestões",
                    columns: cols.map(col => ({ label: col.label, value: col.key })),
                    content: response.data
                }
            ]
            const settings = {
                fileName: "Planilha de sugestões dos clientes",
                extraLength: 3,
                writeOptions: {}
            }

            this.$toasted.global.default({ msg: 'Gerando arquivo' })

            let callback = () => this.$toasted.global.defaultSuccess({ msg: 'Arquivo gerado com sucesso' })
            try {
                xlsx(data, settings, callback)
            } catch (error) {
                this.$toasted.global.defaultError({ msg: 'Erro ao gerar arquivo xlsx' })
            }
        },
        prepareToRemove(sugestion_id) {
            this.deleteSugestion = sugestion_id
        },

        remove(id) {
            this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`
            this.$tallos_admin_api.delete(`sugestions/delete/${id}`, {
                id: id
            })
                .then(() => {
                    this.getSugestions()
                    this.hideModal()
                })
                .catch(() => this.$toasted.global.defaultError({ msg: 'Erro na remoção da Sugestão.' }))
        }
    },

    created: function () {
        this.debouncedGetAnswer = lodash.debounce(this.getSugestions, 500)
    },

    mounted() {
        this.processing = true
        setTimeout(() => {
            if (
                this.$store.state &&
                this.$store.state.admin &&
                this.$store.state.admin.perfil &&
                (this.$store.state.admin.perfil === 'admin')
            ) {
                this.getSugestions()
            }
        }, 700)
        document.title = 'Tallos Admin - Sugestões'
    }
}
</script>

<style scoped>
.module-resource::before {
    content: '';
    width: 18px;
    height: 1px;
    background: #BEC1C5;
    position: absolute;
    left: 36px
}

.sub-module-resource::before {
    content: '';
    width: 18px;
    height: 1px;
    background: #BEC1C5;
    position: absolute;
    left: 66px
}

.lupa-style {
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: 3;
    color: #0008;
    cursor: pointer;
}

#preview-banner {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}

.switch {
    display: flex;
    margin-top: 2px;
    position: relative;
    width: 51px;
    height: 23px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #007bff;
}

input:focus+.slider {
    box-shadow: 0 0 1px #007bff;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.modalImage {
    max-width: 70%;
}

.search-admin {
    position: absolute !important;
    top: 8px;
    right: 10px;
    cursor: pointer;
}
</style>